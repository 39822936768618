import React from 'react'
import styled from 'styled-components';

import Frame from '../Frame';
import Block from '../Block';
import image from '../../assets/partition.png'
import poster1 from '../../assets/babes-in-arms-poster.jpg'
import poster2 from '../../assets/babesinarms.jpg'

const Musica = () => {
    return (
        <Frame>
        <Block>

            <Image src={image} alt='partition' />

            <div className="brown center">
                <h2>My Funny Valentine</h2>
                <h3>From Babes in Arms</h3>
                <h3>Lyrics by Lorenz Hart, music by Richard Rodgers</h3>
                <p>Be hold the way our fine feathered-friend</p>
                <p>This virtue doth parade.</p>
                <p>Thou knowest not my dim witted friend,</p>
                <p>the picture Thou hast made.</p>
                <p>Thy vacant brow and Thy tousled hair</p>
                <p>conceal Thy good intent.</p>
                <p>Thou noble upright, truthful, sincere</p>
                <p>And slightly dopey gent - you are..</p>
                <p>REFRAIN</p>
                <p>My funny valentine</p>
                <p>Sweet comic valentine</p>
                <p>You make me smile with my heart </p>
                <p>You looks are laughable, unphotographable </p>
                <p>Yet you’re my favorite work of art</p>
                <p>Is your figure less than greek</p>
                <p>Is your mouth a little bit weak</p>
                <p>When you open it to speak, are you smart ?</p>
                <p>Don’t change a hair for me</p>
                <p>Not if you care for me</p>
                <p>Stay little valentine stay</p>
                <p>Each day is valentine’s day</p>
            </div>

            <h2>Musicais Americanos</h2>
            <h3>O início</h3>
            <p>A primeira peça teatral adaptada ao moderno conceito de musical foi “The Black Crook” em 12 de Setembro de 1866.</p>
            <h3>Na década de 1920</h3>
            <p>A primeira produção que reunia uma integração entre enredo e partitura foi Show Boat, com roteiro e letras por Oscar Hammerstein e música por Jerome Kern.</p>
            <h3>Na década de 1930</h3>
            <p>Anything Goes de Cole Porter (1934).</p>
            <p>Porgy and Bess de Gershwin (1935).</p> 
            <p>Babes in Arms de Richard Rodgers e Lorenz Hart (1937).</p>
            <h3>A era dourada</h3>
            <p>Oklahoma! de Richard Rodgers e Oscar Hammerstein (1943).</p>
            <p>Annie Get Your Gun de Irving Berlin (1946).</p>                        
            <p>West Side Story de Leonard Bernstein (1957).</p>

            <div className="brown">
                <h2>Babies in Arms</h2>
                <p>A canção apareceu na produção do Musical para Teatro “Babes in Arms”, por Rodgers & Hart. O Musical estreou no Theatre Shubert, em New York, em 14 de abril de 1937 e teve 289 apresentações.</p>
                <p> Music: Richard Rodgers  - Lyrics: Lorenz Hart </p>
                <p>Theatre: Shubert Theatre and Majestic Theatre</p>
                <p>Opened: April 14, 1937  - Closed: December 18, 1937</p>
                <p>Choreographer: George Balanchine</p>
                <p>Richard Rodgers & Lorenz Hart trabalharam junto em aproximadamente trinta musicais de 1919 até a morte de Hart em 1943.</p>
            </div>

            <Posters>
                <Poster1 src={poster1} alt='poster Babes in Arms' />
                <Poster2 src={poster2} alt='poster Babes in Arms' />
            </Posters>

            <Table className="border">
                <h3>Broadway Premiere</h3>
                <table>
                    <tbody>
                        <tr>
                            <td className="right">Theatre</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Shubert Theatre, Majestic Theatre</td>
                        </tr>
                        <tr>
                            <td className="right">Opening Night</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">April 14, 1937</td>
                        </tr>
                        <tr>
                            <td className="right">Total Performances</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">289</td>
                        </tr>
                        <tr>
                            <td className="right">Original Cast</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Mitzi Green, Wynn Murray, Duke McHale, Alfred Drake, Ray McDonald, Grace McDonald, Nicholas Brothers and Dan Dailey</td>
                        </tr>
                        <tr>
                            <td className="right">Director</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Robert Sinclair</td>
                        </tr>
                        <tr>
                            <td className="right">Choreographer</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">George Balanchine</td>
                        </tr>
                        <tr>
                            <td className="right">Musical Director</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Gene Salzer</td>
                        </tr>
                        <tr>
                            <td className="right">Orchestrations</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Hans Spialek</td>
                        </tr>
                        <tr>
                            <td className="right">Scenic Design</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Raymond Sovey</td>
                        </tr>
                        <tr>
                            <td className="right">Costume Design</td>
                            <td className="space">&nbsp;</td>
                            <td className="left">Helene Pons</td>
                        </tr>
                    </tbody>
                </table>
            </Table>            
            
        </Block>
        </Frame>
        );
    }
    
    export default Musica;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.3);
`;

const Posters = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2rem;

    @media (max-width: 425px) {
        flex-direction: column;
    }
`;

const Poster1 = styled.img`
    flex: 1 50%
    max-width: 100%;
    max-height: 100%;
`;

const Poster2 = styled.img`
    flex: 1 50%
    max-width: 100%;
    max-height: 100%;

    @media (max-width: 425px) {
        display: none;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;

    & td {
        padding: .3rem;
    }
    
    & .right {
        width: 30%;
        font-weight: 600;
    }

    & .left {
        width: 65%;
    }
    & .space {
        width: 2%;
    }
`;
    