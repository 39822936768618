import React from 'react'

import Frame from '../Frame';
import Block from '../Block';

const Contato = () => {
    return (
        <Frame>
            <Block>
                <h1>Gerardo Barbosa Lima</h1>
                <p>
                    De Fortaleza. Fez seus estudos de nível superior nas áreas
                    de Economia na UFC e na USP de 1971 a 1974 e Tecnologia da
                    Informação na primeira turma da UFC de 1975 a 1977.
                </p>

                <p>
                    Atuou profissionalmente como principal gestor na área de
                    tecnologia da informação em empresas de grande porte (banco,
                    indústria, governo e comércio) de 1977 a 1993.
                </p>

                <p>
                    Vem atuando desde 1993 em projetos de consultoria nas áreas de:
                </p>

                <ul>
                    <li>Planejamento de uso dos recursos da tecnologia da informação.</li>
                    <li>Diagnóstico e Recomendações na área de TI.</li>
                    <li>Redesenho de processos.</li>
                    <li>Seleção e acompanhamento da implantação de sistemas integrados de gestão.</li>
                </ul>
            </Block>
        </Frame>
    );
}

export default Contato;
