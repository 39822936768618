import React, { Component } from "react";
import Navbar from "./components/navbar/Navbar";
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { TracksProvider } from "./contexts/TracksContext";

import GlobalStyle from "./styles/Global";

class App extends Component {
  state = {
    navbarOpen: false,
  };

  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  };

  render() {
    return (
      <ConfigProvider locale={ptBR}>
        <TracksProvider>
          <Navbar
            navbarState={this.state.navbarOpen}
            handleNavbar={this.handleNavbar}
          />
          <GlobalStyle />
        </TracksProvider>
      </ConfigProvider>
    );
  }
}

export default App;
