import React from "react";
import styled from "styled-components";

import image from "../../assets/chetbaker.png";
import logo from "../../assets/Guinness_World_Records_logo.png";
import portrait from "../../assets/pic_gerardo.jpg";
import Audio from "../home/Audio";
import { useTracks } from "../../contexts/TracksContext";

const Home = () => {
  const { tracks, loading } = useTracks();
  return (
    <>
      <FlexContainer>
        <ImageSection>
          <Image src={image} alt="Photography of Chet Baker" />
          <Audio />
          <PortraitMobile
            src={portrait}
            alt="Picture of Gerardo Lima with the Guinness certificate"
          />
        </ImageSection>
        <TitleSection>
          <Logo src={logo} alt="Logo Guinness World Records" />
          <TitlePage>
            <h1>My Funny Valentine</h1>
            <h2>Uma coleção intangível</h2>
            <p>Maior coleção de versões de uma canção</p>
            {!loading && <p>Agora com {tracks.length} versões</p>}
          </TitlePage>
          <Portrait
            src={portrait}
            alt="Picture of Gerardo Lima with the Guinness certificate"
          />
        </TitleSection>
      </FlexContainer>
      <Footer>&copy; 2020</Footer>
    </>
  );
};

export default Home;

const FlexContainer = styled.div`
  padding-top: 5rem;
  display: flex;
  min-height: calc(100vh - 5rem);
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 5rem 0;
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 50%;

  @media (max-width: 750px) {
    margin-top: 2rem;
    order: 2;
    width: 70%;
  }
`;

const Image = styled.img`
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  border: 3px solid #fff;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10rem;
  flex: 1 50%;
  text-align: center;

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-right: 6rem;
  }

  @media (min-width: 751px) and (max-width: 768px) {
    margin-right: 1.5rem;
  }

  @media (max-width: 750px) {
    order: 1;
    margin: 0 auto;
    width: 70%;
  }
`;

const Logo = styled.img`
  height: 13rem;
  width: 13rem;
  margin: 0 auto;

  @media (max-width: 750px) {
    height: 10rem;
    width: 10rem;
  }
`;

const TitlePage = styled.div`
  margin-top: 3em;
  color: #524c2a;

  & h1 {
    margin-bottom: 2rem;
    font-family: "Charmonman", cursive;
    font-size: 4rem;
    font-weight: 700;
  }

  & h2 {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 500;
  }

  & p {
    font-size: 2rem;
  }

  @media (max-width: 750px) {
    font-family: "Oswald", sans-serif;
    & h1 {
      font-size: 2.5rem;
      font-weight: 700;
    }

    & h2 {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      font-weight: 400;
    }

    & p {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
`;

const Portrait = styled.img`
  margin: 3em auto 0;

  @media (max-width: 750px) {
    display: none;
  }
`;

const PortraitMobile = styled.img`
  margin: 3rem auto 0;

  @media (min-width: 751px) {
    display: none;
  }
`;

const Footer = styled.div`
  height: 5rem;
  line-height: 5rem;
  padding-left: 1rem;
  background-color: #a3a380;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  color: #524c2a;
`;
