import React from 'react';
import styled from 'styled-components';

import Frame from '../Frame';
import Block from '../Block';

import chet from '../../assets/ChetAlbum.jpg';
import k7 from '../../assets/1k7.JPG';
import micro from '../../assets/sony_micro.JPG';
import pochette from '../../assets/pochette.JPG';
import cds from '../../assets/CDs.JPG';
import cd from '../../assets/recording.JPG';


const Colecao = () => {
    return (
        <Frame>
            <Block>

                <p>Em uma tarde de dezembro de 1990, 53 anos depois de sua estréia no musical para teatro “Babes in Arms”, ouví pela primeira vez a bela canção My Funny Valentine, cantada por Chet Baker, no Long Playing "Chet Baker Sings", em gravação feita 1953, na radiola da casa do Marcelo Costa Coelho, no Bairro de Fátima, em Fortaleza.</p>

                <Chet src={chet} alt="Album Chet Baker Sings" />

                
                <p>Depois desta primeira audição comecei a juntar outras versões da canção:</p>
                <p>As gravações iniciais foram feitas em fitas K7 C-60 com 60 minutos, 30 de cada lado. A ordem era cronológica: a cada uma nova versão a sua cópia era feita na fita K7 da vez, até completá-la e iniciar outra. Foram gravadas 60 fitas K7. Elas foram encaminhadas a um estúdio de gravação onde foram digitalizadas, gravadas em CD e copiadas para o disco rígido de um computador.</p>

                <K7 src={k7} alt="audiotape" />
                
                <div className="brown flex flexcolumn">
                    <h2>Como a Coleção foi crescendo</h2>

                    <p>- Amigos ofereceram versões quando souberam</p>
                    <p>- Busca em lojas de disco em Fortaleza ou quando viajava a trabalho</p>
                    <p>- Compra de LP's em sebos: Marchè.</p>
                    <p>- Quando viajava levava minha fita K7 da vez para se encontrasse novas versões gravar no hora</p>
                    <p>- Gravação ao vivo de shows MiniDisc da Sony.</p>

                    <Micro src={micro} alt="micro"/>
                    
                    <p>Amigos contatavam outros amigos que me gravavam fitas K7, e depois CD's, com novas versões.</p>

                    <Pochette src={pochette} alt="audiotape"/>
                </div>

                <h3>Com a chegada a Internet</h3>
                <p>- Busca em lojas de disco virtuais davam dicas de novas versões</p>
                <p>- Compra de cd's pela rede sem impostos: cdnow, cdconnection, amazon</p>
                <p>- Escolha de músicas para fazer cd's personalizados pela rede: customdisc.com, musicmaker.com, wherehouse.com</p>

                <CDs src={cds} alt="customized cd's"/>

                <p>- Sites de músicos</p>

                <p>- Envio de CD's por músicos contactados pela rede</p>

                <p>- Troca de músicas via e-mail pós contato, via sites de troca</p>

                <CD src={cd} alt="recorded cd's"/>

                <p>- Troca de músicas pelos sites: napster, 2 find mp3, 2look4mp3, audiogalaxy, kazaa, vitaminic, gmn, iuma, myplay, lycos, mp3.com, altavista, wimmx, limewire, pressplay.</p>
                
                <p>Hoje resta ainda somente soulseek.</p>

                <p>- Compra de faixas pela rede: liquidaudio.com, e-music.com, imix.com, rhapsody.com, napster.com, mycokrmusic.com, buymusic.com, audiolunchbox.com, itunes.com, msn entertainment, soundclick.com, yahoo.com, i-get.com, virgin.com, sonicstage.com,</p>

                <p>- Sites de músicos coletivos como cdbaby.com e myspace.com</p>

                <p>- Gravação de streaming spotify.com, deezer.com e outros sites que tocam música.</p>

                
                <Table>
                    <div className="brown">
                        <h2 className="center">Versões para outras línguas</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="right">Anny Gould</td><td className="space">&nbsp;</td><td className="left">La Saint Vaurien</td>
                                </tr>
                                <tr>
                                    <td className="right">Aquaserge</td><td className="space">&nbsp;</td><td className="left">Déjà-Vous ?</td>
                                </tr>
                                <tr>
                                    <td className="right">Cserháti Zsuzsa</td><td className="space">&nbsp;</td><td className="left">Életem Zenéje</td>
                                </tr>
                                <tr>
                                    <td className="right">Götz Alsmann</td><td className="space">&nbsp;</td><td className="left">Am Broadway</td>
                                </tr>
                                <tr>
                                    <td className="right">Igor Zakus & Z-band</td><td className="space">&nbsp;</td><td className="left">Canções Ucranianas para Contrabaixo</td>
                                </tr>
                                <tr>
                                    <td className="right">Igor Zakus & Z-band</td><td className="space">&nbsp;</td><td className="left">Ukrainian Bass Song</td>
                                </tr>
                                <tr>
                                    <td className="right">Jorgos Skolias & Bogdan Hołownia</td><td className="space">&nbsp;</td><td className="left">...Tales</td>
                                </tr>
                                <tr>
                                    <td className="right">Josipa Lisac Moj Smiješni Dječače</td>
                                </tr>
                                <tr>
                                    <td className="right">Katja Ebstein</td><td className="space">&nbsp;</td><td className="left">Famous the Finest Female Jazz Today Vol. 7</td>
                                </tr>
                                <tr>
                                    <td className="right">Katja Ebstein</td>
                                </tr>
                                <tr>
                                    <td className="right">Lorette Helou</td><td className="space">&nbsp;</td><td className="left">New Order</td>
                                </tr>
                                <tr>
                                    <td className="right">Marina Zadorozhnaya</td><td className="space">&nbsp;</td><td className="left">ReverbNation</td>
                                </tr>
                                <tr>
                                    <td className="right">Nara Leão</td><td className="space">&nbsp;</td><td className="left">These Foolish Things</td>
                                </tr>
                                <tr>
                                    <td className="right">Oussama Rahbani</td><td className="space">&nbsp;</td><td className="left">New Order</td>
                                </tr>
                                <tr>
                                    <td className="right">Rima Khcheich</td><td className="space">&nbsp;</td><td className="left">Yalalalli (Ma Baaraef Oul)</td>
                                </tr>
                                <tr>
                                    <td className="right">Roberta Sdolfo with Alberto Bonacasa Trio</td><td className="space">&nbsp;</td><td className="left">Spirito Del Vento</td>
                                </tr>
                                <tr>
                                    <td className="right">Thomas Pigor</td><td className="space">&nbsp;</td><td className="left">Singt Benedikt Eichhorn</td>
                                </tr>
                                <tr>
                                    <td className="right">Whirimako Black</td><td className="space">&nbsp;</td><td className="left">Te Reo Maori Sessions</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="brown">
                    <h2>Gravações curiosas</h2>
                    <table>
                        <tbody>
                            <tr><td className="right">Bill Clinton</td></tr>
                            <tr><td className="right">Fiona Cclifton-Welker</td><td className="space">&nbsp;</td><td className="left">Harpa</td></tr>
                            <tr><td className="right">Jason the Argonaut</td><td className="space">&nbsp;</td><td className="left">Poesia rap em cima de solo de cello</td></tr>
                            <tr><td className="right">Jin Self</td><td className="space">&nbsp;</td><td className="left">Tuba</td></tr>
                            <tr><td className="right">Ken Nordine</td><td className="space">&nbsp;</td><td className="left">Só a letra declamada</td></tr>
                            <tr><td className="right">A maior</td><td className="space">&nbsp;</td><td className="left">Grateful Dead com 24:22</td></tr>
                            <tr><td className="right">A menor</td><td className="space">&nbsp;</td><td className="left">Berlin Alexanderplatz com 00:35</td></tr>
                        </tbody>
                    </table>
                    </div>

                    <div className="brown">
                        <h2>Interpretes cearenses</h2>
                        <table>
                            <tbody>
                                <tr><td className="right">Aroldo Araújo & Antonio José</td><td className="space">&nbsp;</td><td className="left">Ao vivo</td></tr>
                                <tr><td className="right">Elismario</td><td className="space">&nbsp;</td><td className="left">Ao vivo no Café Pagliuca</td></tr>
                                <tr><td className="right">Fatima Santos</td><td className="space">&nbsp;</td><td className="left">Ao vivo</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Café e Cointreau</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Festival de Jazz & Blues</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Muito Pessoal</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Urbanita</td></tr>
                                <tr><td className="right">Marcio Resende & Tarcisio Sardinha</td><td className="space">&nbsp;</td><td className="left">Ao vivo na Zug Choperia</td></tr>
                                <tr><td className="right">Carlos Magno</td><td className="space">&nbsp;</td><td className="left">Ao vivo no meu aniversário</td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="brown">
                    <h2>Trilhas Sonoras</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="right">Let’s Get Lost (1988)</td><td className="space">&nbsp;</td><td className="left">Biography of Chet Baker</td>
                                </tr>
                                <tr>
                                    <td className="right">Handicap (1968)</td><td className="space">&nbsp;</td><td className="left">Duke Ellington</td>
                                </tr>
                                <tr>
                                    <td className="right">Sharky’s Machine (1981)</td><td className="space">&nbsp;</td><td className="left">Chet Baker</td>
                                </tr>
                                <tr>
                                    <td className="right">The Fabulous Baker Boys (1989)</td><td className="space">&nbsp;</td><td className="left">performed by Michelle Pfeiffer</td>
                                </tr>
                                <tr>
                                    <td className="right">Malice (1993)</td><td className="space">&nbsp;</td><td className="left">performed by Gene Harris</td>
                                </tr>
                                <tr>
                                    <td className="right">Waiting to Exhale (1995)</td><td className="space">&nbsp;</td><td className="left">performed by Chaka Khan</td>
                                </tr>
                                <tr>
                                    <td className="right">The Talented Mr. Ripley (1999)</td><td className="space">&nbsp;</td><td className="left">performed by Matt Damon</td>
                                </tr>
                                <tr>
                                    <td className="right">The Cooler (2003)</td><td className="space">&nbsp;</td><td className="left">performed by Tierney Sutton</td>
                                </tr>
                                <tr>
                                    <td className="right">The Company (2003)</td><td className="space">&nbsp;</td><td className="left">5 different versions performed by: Marvin Laird and Clay Ruede, Elvis Costello, Lee Wiley, Chet Baker, and Kronos Quartet.</td>
                                </tr>
                                <tr>
                                    <td className="right">Ashura (2005)</td><td className="space">&nbsp;</td><td className="left">performed by Sting</td>
                                </tr>
                                <tr>
                                    <td className="right">Pal Joey (1957)</td><td className="space">&nbsp;</td><td className="left">performed by Kim Novak</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="brown">
                        <h2>Interpretes Brasileiros</h2>
                        <table>
                            <tbody>
                                <tr><td className="right">Alexandre Silvério</td><td className="space">&nbsp;</td><td className="left">Live Concerts</td></tr>
                                <tr><td className="right">Alexandre Silvério Quinteto</td><td className="space">&nbsp;</td><td className="left">Entre Mundos</td></tr>
                                <tr><td className="right">Ana Paula Barreiro</td><td className="space">&nbsp;</td><td className="left">Ao vivo com Leandro & Os Melodicos</td></tr>
                                <tr><td className="right">Araken Peixoto</td><td className="space">&nbsp;</td><td className="left">Quando os Peixotos se encontram</td></tr>
                                <tr><td className="right">Bossa Nova Jazz trio</td><td className="space">&nbsp;</td><td className="left">Ao Vivo No Mistura Fina</td></tr>
                                <tr><td className="right">Cafe Jazz</td></tr>
                                <tr><td className="right">Caique Vieira</td><td className="space">&nbsp;</td><td className="left">Solo em casa</td></tr>
                                <tr><td className="right">Camilla Inês</td><td className="space">&nbsp;</td><td className="left">Jazzmine</td></tr>
                                <tr><td className="right">Carlos Augusto Jazz Trio</td><td className="space">&nbsp;</td><td className="left">Ao vivo no Café Jazz</td></tr>
                                <tr><td className="right">Carlos Dias</td><td className="space">&nbsp;</td><td className="left">Copacabana Station</td></tr>
                                <tr><td className="right">Carlos Magno</td><td className="space">&nbsp;</td><td className="left">Ao vivo no meu aniversário</td></tr>
                                <tr><td className="right">Carolina Serdeira</td></tr>
                                <tr><td className="right">Cauby Peixoto</td><td className="space">&nbsp;</td><td className="left">Homenagem a Frank Sinatra</td></tr>
                                <tr><td className="right">Cecilia Dale</td><td className="space">&nbsp;</td><td className="left">Standard in Bossa II</td></tr>
                                <tr><td className="right">Chiquinho do Acordeon</td><td className="space">&nbsp;</td><td className="left">Dançando no Rio</td></tr>
                                <tr><td className="right">Cida Moreira</td><td className="space">&nbsp;</td><td className="left">No Teatro Sesc Vila Mariana</td></tr>
                                <tr><td className="right">Cipó</td><td className="space">&nbsp;</td><td className="left">A Fantástica Orquestra de Stúdio de Cipó</td></tr>
                                <tr><td className="right">Cláudio Lima e Rubens Salles</td><td className="space">&nbsp;</td><td className="left">Cada Mesa é um Palco</td></tr>
                                <tr><td className="right">Cris Oak</td><td className="space">&nbsp;</td><td className="left">Estudos De Jazz E Bossa Nova</td></tr>
                                <tr><td className="right">Daniel Pinheiro</td></tr>
                                <tr><td className="right">Dick Farney</td><td className="space">&nbsp;</td><td className="left">Um Piano ao Cair da Tarde Vol. 2</td></tr>
                                <tr><td className="right">Dick Farney</td><td className="space">&nbsp;</td><td className="left">E você</td></tr>
                                <tr><td className="right">Dick Farney com Waldemiro Lemke e Sua Orquestra</td><td className="space">&nbsp;</td><td className="left">Cancoes para a Noite de meu Bem</td></tr>
                                <tr><td className="right">Djalma Ferreira e os Milio- narios do Ritmo</td><td className="space">&nbsp;</td><td className="left">Depois do Drink</td></tr>
                                <tr><td className="right">Dolores Duran</td><td className="space">&nbsp;</td><td className="left">10 - A Noite de Dolores (2010)</td></tr>
                                <tr><td className="right">Eliana Pittman</td><td className="space">&nbsp;</td><td className="left">Sempre Present</td></tr>
                                <tr><td className="right">Elis Regina</td><td className="space">&nbsp;</td><td className="left">Elis Regin</td></tr>
                                <tr><td className="right">Elismario</td><td className="space">&nbsp;</td><td className="left">Ao vivo no Café Pagliuc</td></tr>
                                <tr><td className="right">Estado de Espírito (part. de Walter Bandeir)</td><td className="space">&nbsp;</td><td className="left">From Daqui</td></tr>
                                <tr><td className="right">Fatima Santos</td><td className="space">&nbsp;</td><td className="left">Ao viv</td></tr>
                                <tr><td className="right">Gal Costa</td><td className="space">&nbsp;</td><td className="left">Show Plura</td></tr>
                                <tr><td className="right">George Arrunateghi</td><td className="space">&nbsp;</td><td className="left">Let's Fal In Love</td></tr>
                                <tr><td className="right">Guilherme Coutinho</td><td className="space">&nbsp;</td><td className="left">Guilherme Coutinho e a curtiçã</td></tr>
                                <tr><td className="right">Guimarães e seu conjunto</td><td className="space">&nbsp;</td><td className="left">Dançando No Fred’</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Café e Cointrea</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Festival de Jazz & Blue - ao vivo</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Muito Pessoa</td></tr>
                                <tr><td className="right">Idilva Germano</td><td className="space">&nbsp;</td><td className="left">Urbanit</td></tr>
                                <tr><td className="right">Ivan Meyer & Sonia Meziat</td><td className="space">&nbsp;</td><td className="left">ExplicSax</td></tr>
                                <tr><td className="right">Izzy Gordon</td><td className="space">&nbsp;</td><td className="left">Aos Mestres com Carinh</td></tr>
                                <tr><td className="right">Jehovah Da Gaita</td><td className="space">&nbsp;</td><td className="left">Soul Harmonic</td></tr>
                                <tr><td className="right">João Donato & Olívia Byington</td><td className="space">&nbsp;</td><td className="left">Program de TV</td></tr>
                                <tr><td className="right">José Eduardo</td></tr>
                                <tr><td className="right">Jovino Santos Neto</td><td className="space">&nbsp;</td><td className="left">Piano Masters Volume 4</td></tr>
                                <tr><td className="right">Lana Bittencourt</td><td className="space">&nbsp;</td><td className="left">Lana ao vivo no 1800</td></tr>
                                <tr><td className="right">Laurindo de Almeida</td><td className="space">&nbsp;</td><td className="left">Broadway Solo Guitar</td></tr>
                                <tr><td className="right">Lauro Miranda</td><td className="space">&nbsp;</td><td className="left">Lauro Miranda e sua Orquestra</td></tr>
                                <tr><td className="right">Leny Andrade</td><td className="space">&nbsp;</td><td className="left">Dick Farney e sua Orquestra</td></tr>
                                <tr><td className="right">Leny Andrade</td><td className="space">&nbsp;</td><td className="left">Maiden Voyage</td></tr>
                                <tr><td className="right">Lorna Earnshaw</td><td className="space">&nbsp;</td><td className="left" >A Hora</td></tr>
                                <tr><td className="right">Luis Guerra & Israel Sandoval</td><td className="space">&nbsp;</td><td className="left">Recordando a Bill Evans & Jim Hall</td></tr>
                                <tr><td className="right">Luiz Arruda Paes</td><td className="space">&nbsp;</td><td className="left">Piano Romântico</td></tr>
                                <tr><td className="right">Luiz Claudio Romântico</td></tr>
                                <tr><td className="right">Maestro Cipó</td><td className="space">&nbsp;</td><td className="left">A Fantástica Orquestra de Stúdio de Cipo</td></tr>
                                <tr><td className="right">Marcelo Bastos</td><td className="space">&nbsp;</td><td className="left">Remembering New York City</td></tr>
                                <tr><td className="right">Marcia Mell</td><td className="space">&nbsp;</td><td className="left">Sentimento Blues</td></tr>
                                <tr><td className="right">Marcio Resende & Tarcisio Sardinha</td><td className="space">&nbsp;</td><td className="left">Ao vivo na Zug Choperia</td></tr>
                                <tr><td className="right">Marcio Resende, Toninho Horta & Jorge Helder</td><td className="space">&nbsp;</td><td className="left">Ao vivo no Festival Cocoricó</td></tr>
                                <tr><td className="right">Marco Pereira</td><td className="space">&nbsp;</td><td className="left">Ao Vivo</td></tr>
                                <tr><td className="right">Marco Pereira</td><td className="space">&nbsp;</td><td className="left">O Samba da Minha Terra</td></tr>
                                <tr><td className="right">Marco Pereira and Martin Taylor</td><td className="space">&nbsp;</td><td className="left">International Guitar Festival 20030116 Mt Vernon, WA</td></tr>
                                <tr><td className="right">Marco Pereira e Ulisses Rocha</td><td className="space">&nbsp;</td><td className="left">Música Viva</td></tr>
                                <tr><td className="right">Markos Resende</td></tr>
                                <tr><td className="right">Maysa</td><td className="space">&nbsp;</td><td className="left">Em Portugal - 1959</td></tr>
                                <tr><td className="right">Moacyr Marques e Seu Conjunto</td><td className="space">&nbsp;</td><td className="left">Jazz & Bossa Nova</td></tr>
                                <tr><td className="right">Moacyr Silva e Seu Conjunto</td><td className="space">&nbsp;</td><td className="left">Meia Noite no Meia Noite</td></tr>
                                <tr><td className="right">Nara Leão</td><td className="space">&nbsp;</td><td className="left">These Foolish Things</td></tr>
                                <tr><td className="right">Nelson Veras</td><td className="space">&nbsp;</td><td className="left">Solo Session (Volume 1)</td></tr>
                                <tr><td className="right">Nestico Aguiar</td></tr>
                                <tr><td className="right">Nouvelle Coisine</td><td className="space">&nbsp;</td><td className="left">low Food</td></tr>
                                <tr><td className="right">Orquestra do Maestro Cipó</td><td className="space">&nbsp;</td><td className="left">Aqui Começa o Mundo Maravilhoso da Música</td></tr>
                                <tr><td className="right">Os Poligonais</td><td className="space">&nbsp;</td><td className="left">Novas Idéias</td></tr>
                                <tr><td className="right">Oscar Castro Neves/John Klemmer</td><td className="space">&nbsp;</td><td className="left">Duo Simpático</td></tr>
                                <tr><td className="right">Rachel Maia</td></tr>
                                <tr><td className="right">Rafael Maia</td><td className="space">&nbsp;</td><td className="left">Flor Azul</td></tr>
                                <tr><td className="right">Renato Vasconcellos</td><td className="space">&nbsp;</td><td className="left">Ao Vivo em Brasilia</td></tr>
                                <tr><td className="right">Ricardo Andrade</td><td className="space">&nbsp;</td><td className="left">14 Temas Eternos</td></tr>
                                <tr><td className="right">Ricardo Werther</td><td className="space">&nbsp;</td><td className="left">Turning Point</td></tr>
                                <tr><td className="right">Rodrigo Rodrigues</td><td className="space">&nbsp;</td><td className="left">Fake Standards</td></tr>
                                <tr><td className="right">Rodrigo Sha</td><td className="space">&nbsp;</td><td className="left">Tom</td></tr>
                                <tr><td className="right">Românticos de Cuba</td><td className="space">&nbsp;</td><td className="left">No Cinema</td></tr>
                                <tr><td className="right">Rosana Judkowitch</td><td className="space">&nbsp;</td><td className="left">Impar</td></tr>
                                <tr><td className="right">Simone Mazzer</td><td className="space">&nbsp;</td><td className="left">Duas Noites com Dolores Duran</td></tr>
                                <tr><td className="right">Simone Moura</td></tr>
                                <tr><td className="right">Suely Costa</td><td className="space">&nbsp;</td><td className="left">Ao vivo em sua casa</td></tr>
                                <tr><td className="right">Tocata Brasil</td><td className="space">&nbsp;</td><td className="left">Tocata Brasil</td></tr>
                                <tr><td className="right">Toninho Horta</td><td className="space">&nbsp;</td><td className="left">Once I Loved</td></tr>
                                <tr><td className="right">Walter Wanderley</td><td className="space">&nbsp;</td><td className="left">Eu, Você e Walter Wanderley</td></tr>
                                <tr><td className="right">Wanderléa</td><td className="space">&nbsp;</td><td className="left">Nova estação</td></tr>
                            </tbody>
                        </table>
                    </div>
                </Table>
            </Block>
        </Frame>
    );
}

export default Colecao;

const Chet = styled.img`
    max-width: 50%;
    max-height: 50%;
`;

const K7 = styled.img`
    max-width: 45%;
    max-height: 45%;
`;

const Micro = styled.img`
    max-width: 35%;
    max-height: 35%;
`;

const Pochette = styled.img`
    max-width: 35%;
    max-height: 35%;
`;

const CDs = styled.img`
    max-width: 50%;
    max-height: 50%;
`;

const CD = styled.img`
    max-width: 40%;
    max-height: 40%;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;

    & table {
        margin: auto;
        max-width: 100%;
    }

    & td {
        padding: .3rem;
    }
    
    & .right {
        width: 49%;
        font-weight: 600;
    }

    & .left {
        width: 49%;
    }
    & .space {
        width: 2%;
    }
`;
