import React from 'react';
import styled from 'styled-components';

import Frame from '../Frame';
import Block from '../Block';

import compositors from '../../assets/Rodgers_and_Hart.jpg';
import rodgers from '../../assets/Rodgers.JPG';
import hart from '../../assets/Hart.JPG';

const Compositores = () => {
    return (
        <Frame>
            <Block>

                <h2>Richard Rodgers & Lorenz Hart trabalharam junto em 30 musicais de 1919 até a morte de Hart em 1943</h2>

                <Compositors src={compositors} alt="Richard Rodgers and Lorenz Hart" />

                <div className="center brown">
                    <h2>Músicas compostas pela dupla</h2>
                    <p>My Funny Valentine</p>
                    <p>Blue Moon</p>
                    <p>Little Girl Blue</p>
                    <p>Manhattan</p>
                    <p>Have You Met Miss Jones?</p>
                    <p>Isn't It Romantic</p>
                    <p>My Romance</p>
                    <p>This Can't Be Love</p>
                    <p>The Lady Is a Tramp</p>
                    <p>With a Song in My Heart</p>
                    <p>He Was Too Good to Me</p>
                    <p>My Heart Stood Still</p>
                </div>

                <Rodgers src={rodgers} alt="Richard Rodgers" />

                <h3>Ele dizia que não era compositor e sim contador de estórias com música. Depois da morte do Lorenz Hart ele iniciou uma parceria com Oscar Hammerstein II com o Musical “A Noviça Rebelde.</h3>

                <a href="https://en.wikipedia.org/wiki/Richard_Rodgers">https://en.wikipedia.org/wiki/Richard_Rodgers</a>

                <p>Nascido em uma família judia alemã próspera em Arverne, Queens , New York City, Rodgers era filho de Mamie (Levy) e Dr. William Abrahams Rodgers, um médico proeminente que tinha mudado o nome da família de Rogazinsky. Richard começou a tocar piano aos seis anos de idade. Ele participou PS 166, Townsend Harris Hall e DeWitt Clinton High Escola . Rodgers passou seus primeiros verões adolescentes em Camp Wigwam ( Waterford, Maine ), onde ele compôs algumas de suas primeiras canções.</p>

                <p>Rodgers, Lorenz Hart , e mais tarde colaborador Oscar Hammerstein II tudo assistiu Universidade de Columbia . Em Columbia, Rodgers se juntou ao Pi Lambda Phi fraternidade. Em 1921, Rodgers trocou seus estudos para o Instituto de Arte Musical (agora a Juilliard School ). Rodgers foi influenciada por compositores como Victor Herbert e Jerome Kern , bem como pelos operetas seus pais o levaram para ver em Broadway quando ele era uma criança.</p>

                <Hart src={hart} alt="Lorenz Hart" />

                <h3>Filho de judeus imigrantes Hart estudou jornalismo e trabalhou como tradutor de peças teatrais alemãs até conhecer Richard Rodgers. A morte da mãe viúva com quem vivia e o alto consumo de álcool e levou à sua prematura morte com 48 anos.</h3>

                <a href="https://en.wikipedia.org/wiki/Lorenz_Hart">https://en.wikipedia.org/wiki/Lorenz_Hart</a>

                <p>Hart nasceu em Harlem , o mais velho dos dois filhos, de judeus imigrantes pais, Max M. e Frieda (Isenberg) Hart, de origem alemã. Através de sua mãe, ele era um grande-sobrinho-neto do poeta alemão Heinrich Heine . Seu pai, um promotor de negócios, enviou Hart e seu irmão para escolas particulares. (Seu irmão, Teddy Hart, também entrou em teatro e tornou-se uma estrela da comédia musical. A esposa de Teddy Hart, Dorothy Hart, escreveu uma biografia de Lorenz Hart.)</p>

                <p>Hart recebeu sua educação da Columbia Grammar School e depois participaram Universidade de Columbia School of Journalism por dois anos. Em 1919, um amigo o apresentou a Richard Rodgers e os dois uniram forças para escrever canções para uma série de produções amadoras e estudantis.</p>

                <p>Em 1918, Hart estava trabalhando para os Shubert irmãos, parceiros no teatro, traduzindo peças alemãs para o Inglês. Em 1919, sua canção e Rodgers' "Qualquer Lugar Old With You" foi incluído na comédia musical da Broadway A Lonely Romeo . Em 1920, seis de suas músicas foram usadas na comédia musical Pobre Menina Ritz , que também tinha música por Sigmund Romberg . Eles foram contratados para escrever o placar para os 1925 Theatre Guild produção The Garrick Gaieties , cujo sucesso trouxe aclamação.</p>

                <div className="brown">
                    <h2>Richard Rodgers e Lorenz Hart</h2>

                    <p>Richard Rodgers e Lorenz Hart foram introduzidos em 1919, quando ambos  articiparam Universidade de Columbia , quando pediu para escrever um show amador clube. Depois de escrever juntos por vários anos, eles produziram seu primeiro sucesso Broadway musical, The Garrick Gaieties , em 1925, que introduziu a sua canção de sucesso, " Manhattan " e levou a uma série de musicais de sucesso e filmes. Eles rapidamente se tornou entre os compositores mais populares nos Estados Unidos, e 1925-1931 tinha quinze dezenas destaque na Broadway. No início dos anos 1930 eles se mudaram para Hollywood, onde criaram várias canções populares para o cinema, como " não que é romântico? " E " amante ", antes de voltar a Broadway em 1935 com Billy Rose 's Jumbo . De 1935 a morte de Hart em 1943, eles escreveram uma série de musicais da Broadway altamente considerado, a maioria dos quais foram hits.</p>

                    <p>Muitos de seus musicais de teatro a partir do final dos anos 1930 foram transformados em filmes, como On Your Toes (1936) e Babes in Arms (1937), embora raramente com suas pontuações intacta. Pal Joey (1940), denominado sua "obra-prima", tem um livro de The New Yorker escritor John O'Hara . O'Hara adaptado suas próprias histórias curtas para o show, que contou com a personagem-título, que é um calcanhar . Então inabalável era o retrato que o crítico Brooks Atkinson famosa pergunta em sua avaliação "Embora seja habilmente feito, como você pode tirar água doce a partir de uma falta bem?" Quando o show foi retomado em 1952, o público tinha aprendido a aceitar material mais escuro (em grande parte graças ao trabalho Rodgers' com Oscar Hammerstein II ). A nova produção teve um consideravelmente mais longo prazo do que o original e foi agora considerado um clássico pela crítica. Atkinson, revendo o renascimento, escreveu que "renova a confiança no profissionalismo do teatro."</p>

                    <p>Time Magazine dedicou uma reportagem de capa de Rodgers e Hart (26 de setembro de 1938). Eles escreveram que o seu sucesso "repousa sobre um instinto comercial que a maioria de seus rivais aparentemente ignorado". O artigo também observou o "espírito de aventura". "Como Rodgers e Hart vê-lo, o que estava matando musicomedy [ sic ] foi a sua semelhança, sua mansidão, sua rima eterna de junho com lua."</p>

                    <p>Suas canções têm sido favoritos de cantores cabaret e artistas de jazz. Por exemplo, Ella Fitzgerald gravou seu songbook. Andrea Marcovicci base uma de suas apresentações de cabaré inteiramente em canções de Rodgers e Hart.</p>

                    <p>Letras de Hart, facile, vernáculo, deslumbrante, às vezes brincalhão, às vezes melancólica, elevou o padrão para a Broadway songwriting. "Sua capacidade de escrever de forma inteligente e para chegar a inesperados, rimas polysyllabic era uma espécie de marca registrada, mas também tinha a capacidade ainda mais raro para escrever com o máximo de simplicidade e profunda emoção." Rodgers, como um criador de melodias, classifica com Jerome Kern e Irving Berlin.</p>

                    <p>Seus shows pertencem à época em que os musicais eram revue-like e libretos não eram muito mais do que desculpas para atuações cômicas e pistas de música. Ainda, assim como suas canções foram um corte acima, de modo que a equipe tentar elevar o padrão da forma musical em geral. Assim, A Connecticut Yankee (1927) foi baseada em Mark Twain novelas, e os meninos de Syracuse (1938) sobre William Shakespeare's A Comédia dos Erros. "Eles sempre tinha considerado a integração da história e música um fator crucial em um show de sucesso." Eles usaram dança significativamente no seu trabalho, usando os balés de George Balanchine.</p>

                    <p>Comparações entre Rodgers e Hart ea equipe sucessor de Rodgers e Hammerstein são inevitáveis. As letras de Hammerstein projetar calor, otimismo sincero e corniness ocasional. Letras de Hart mostrou maior sofisticação no assunto, mais uso de inteligência verbal evidente, e mais de um ou sensibilidade "New York" "Broadway". O arquétipo Rodgers e Hart canção, "Manhattan", rima "A grande cidade grande é um brinquedo maravilhoso / Just feito para uma menina e um menino" na primeira estrofe, em seguida, repete com o "clamor da cidade nunca pode estragar / Os sonhos de um menino e Goil" no passado. Muitas das canções ("Falling in Love with Love", "Little Girl Blue", "My Funny Valentine") são melancólico ou triste, e ambivalência emocional parece ser perceptível no fundo mesmo das canções mais ensolarados. Por exemplo, "Você se aproveitou de mim" parece ser uma evocação da alegria amorosa, mas o próprio título sugere algumas dúvidas quanto a saber se o relacionamento é mútuo ou exploradores.</p>
                </div>

            </Block>
        </Frame>
    );
}
    
export default Compositores;

const Compositors = styled.img`
    max-width: 60%;
    max-height: 60%;
`;

const Rodgers = styled.img`
    max-width: 60%;
    max-height: 60%;
`;

const Hart = styled.img`
    max-width: 60%;
    max-height: 60%;
`;
