import React from 'react'

import Frame from '../Frame';
import Block from '../Block';

const Agradecimentos = () => {
    return (
        <Frame>
            <Block>
                <h1>AGRADECIMENTOS</h1>
                <p>
                    A todos os amigos que fizeram a gentileza de me encaminhar
                    gravações de My Funny Valentine em LPs ou CDs para cópia ou
                    em arquivos mp3:
                    Adalberto Pinto, Agamenon Oliveira, Alan Romero, Alice,
                    Aninha Cançado, Augusto Cesar, Carvalhedo, César Augusto,
                    Clauder Ciarline, Claudinha Ernesto, Evangê, Fausto Nilo,
                    Fernando Costa, Flávio Mancini, Flávio Torres, Galba Lobo,
                    Geraldo Sérgio, Gerardo Barbosa, Gil Sá, Guga De Castro,
                    Henilton Menezes, Heriberto Porto, Jair Kitner, Jorderian
                    Nobre, Juarez Sampaio, Júlio Cavalcante, Léo Rocha,
                    Leonardo Barroso, Lilia Avelar, Lucius Maia, Luís Augusto
                    Sobral, Luís Carlos, Magno, Marcelo Coelho, Marcelo
                    Marcilio, Márcio Távora, Maurício Matos, Nelson Augusto,
                    Pagliuca, Paul Saint Pierre, Paulo André, Paulo Figueiredo,
                    Pete Magadinni, Regina Makaren, Roberto Barroso, Danilo
                    Barbosa Lima, Wagner Oliveira e Zé Airton.
                </p>

                <p>
                    Agradecimento especial ao meu irmão de Iguatu (radicado em
                    São Paulo) jornalista Sérgio de Oliveira pelas inúmeras
                    gravações desta bela canção, garimpadas em seu acervo de
                    LPs e CDs e em suas andanças pelos sebos da internet.
                </p>
                <p>
                    Aos amigos Fausto Nilo, Augusto César Costa, Flavio Torres
                    e Nirez que examinaram e atestaram a veracidade da minha
                    solicitação de reconhecimento da coleção junto ao Guinness
                    World Records. 
                </p>

                <p>
                    Ao Flávio Torres que com o seu proficiente inglês me ajudou
                    a registrar a coleção no Guinness World Records.
                </p>

                <p>
                    Ao Gerardo Lima, à Ludivine Poussier e à Júlia Braga, pela
                    liberação do novo site para divulgação da coleção, e ao
                    Lucas Brilhante pela sua conclusão. E também ao Ricardo
                    Avelar que preparou o primeiro site, hoje desativado pela
                    tecnologia.
                </p>

            </Block>
        </Frame>
    );
}

export default Agradecimentos;
