import React from 'react'
import styled from 'styled-components';

import logo from '../assets/Guinness_World_Records_logo.png'
import Block from '../components/Block'
import Footer from '../components/Footer'

const Frame = ({ children, noBlock }) => {
  return (
    <>
      <ContainerFrame>
        <FlexFrame>
          <LogoFrame src={logo} alt='Logo Guinness World Records' />
          <TitleFrame>
            <h1>My Funny Valentine</h1>
            <p>Maior coleção de versões de uma canção</p>
          </TitleFrame>
        </FlexFrame>

        {noBlock ? children : <Block>{children}</Block>}
      </ContainerFrame>
      <Footer />
    </>
  )
}

export default Frame

const ContainerFrame = styled.div`
  min-height: calc(100vh - 5rem);
  padding-top: 5rem;
`;

const FlexFrame = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 12rem;

  @media (max-width: 640px) {
    display: none;
  }
`;

const TitleFrame = styled.div`
  color: #524c2a;

  & h1 {
    margin-bottom: 1rem;
    font-family: 'Charmonman', cursive;
    font-size: 3rem;
    font-weight: 700;
  }

  & p {
    font-size: 1.5rem;
  }
`;

const LogoFrame = styled.img`
  height: 8rem;
  width: 8rem;
`;
