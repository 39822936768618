import React from "react";

import Frame from "../Frame";
import InterpreteTable from "./InterpretesTable";

const Interpretes = () => {
  return (
    <Frame>
      <InterpreteTable />
    </Frame>
  );
};

export default Interpretes;
