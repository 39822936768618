import React, { useRef, useState, useCallback } from "react";
import styled from "styled-components";
import { Button, Input, Space, Table, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useTracks } from "../../contexts/TracksContext";

import "antd/dist/antd.css";

const columnsNamesInJson = {
  INTERPRETE: "interprete",
  ALBUM: "album",
  TEMPO: "tempo",
  DATA_INCLUSAO: "dataInclusao",
};

const getColumnWidth = (index) => {
  switch (index) {
    case columnsNamesInJson.INTERPRETE:
      return "35%";
    case columnsNamesInJson.ALBUM:
      return "45%";
    case columnsNamesInJson.TEMPO:
      return "7%";
    default:
      // Ao cair nesse caso, a coluna é calculada de acordo com o espaço restante
      return undefined;
  }
};

function InterpretesTable() {
  const searchInput = useRef();
  const { tracks, loading } = useTracks();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState();

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm({ closeDropdown: true });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText("");
  }, []);

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Pesquisar por ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 106 }}
            >
              Procurar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 106 }}
            >
              Resetar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ fontSize: 20, color: filtered ? "#1890ff" : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    }),
    [handleReset, handleSearch, searchText, searchedColumn]
  );

  const getColumnSortProps = useCallback(
    (column) => ({
      sorter: (a, b) => a[column].localeCompare(b[column]),
      sortDirections: ["ascend", "descend", "ascend"],
    }),
    []
  );

  if (loading)
    return (
      <SpinnerContainer>
        <Spin />
      </SpinnerContainer>
    );

  const columns = [
    {
      title: "Interprete",
      dataIndex: columnsNamesInJson.INTERPRETE,
      key: columnsNamesInJson.INTERPRETE,
      width: getColumnWidth(columnsNamesInJson.INTERPRETE),
      defaultSortOrder: "ascend",
      ...getColumnSortProps(columnsNamesInJson.INTERPRETE),
      ...getColumnSearchProps(columnsNamesInJson.INTERPRETE),
    },
    {
      title: "Tempo",
      dataIndex: columnsNamesInJson.TEMPO,
      key: columnsNamesInJson.TEMPO,
      width: getColumnWidth(columnsNamesInJson.TEMPO),
    },
    {
      title: "Álbum",
      dataIndex: columnsNamesInJson.ALBUM,
      key: columnsNamesInJson.ALBUM,
      width: getColumnWidth(columnsNamesInJson.ALBUM),
      ...getColumnSortProps(columnsNamesInJson.ALBUM),
      ...getColumnSearchProps(columnsNamesInJson.ALBUM),
    },
    {
      title: "Inclusão",
      dataIndex: columnsNamesInJson.DATA_INCLUSAO,
      key: columnsNamesInJson.DATA_INCLUSAO,
      width: getColumnWidth(columnsNamesInJson.DATA_INCLUSAO),
      ...getColumnSortProps(columnsNamesInJson.DATA_INCLUSAO),
      sorter: (a, b) => {
        const dateA = a[columnsNamesInJson.DATA_INCLUSAO]
          .split("/")
          .reverse()
          .join();
        const dateB = b[columnsNamesInJson.DATA_INCLUSAO]
          .split("/")
          .reverse()
          .join();
        return dateA < dateB ? -1 : 1;
      },
    },
  ];

  const dataSource =
    tracks &&
    tracks.map((row, index) => ({
      key: index,
      ...row,
    }));

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        //scroll={{ y: "calc(60vh - 55px - 6rem)" }}
        scroll={{ x: 0 }}
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  padding: 8px;
  background: transparent;

  li {
    text-align: center;
    padding: 0;
  }

  .ant-table-body {
    min-height: calc(60vh - 55px - 6rem);
  }

  .ant-empty {
    min-height: calc(60vh - 55px - 6rem - 99px);
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 4px;
  }

  .ant-pagination-item a {
    overflow-wrap: normal;
  }

  .ant-table,
  .ant-table-thead th.ant-table-column-sort,
  .ant-table-thead > tr > th,
  .ant-table-filter-trigger,
  td.ant-table-column-sort {
    background: transparent;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default InterpretesTable;
