import React from 'react'
import styled from "styled-components";

const Brand = () => {
    return (
        <TitleNavBar>My Funny Valentine</TitleNavBar>
    )
}

export default Brand

const TitleNavBar = styled.div`
    font-family: 'Charmonman', cursive;
    margin-top: 1.1rem;
    font-size: 2rem;
    color: #8c8047;
    font-weight: 700;
`;
