import React from 'react'
import styled from 'styled-components';

import Frame from '../Frame';
import Block from '../Block';
import Opovo from '../../assets/opovo.jpg';

const Imprensa = () => {
    return (
        <Frame>
            <Block>
                <h1>Jornal O Povo, Fortaleza, 21 de Agosto de 2006</h1>

                <h2>O caçador de valentinas</h2>
                <h3 className="center">O colecionador cearense Gerardo Barbosa ganhou certificado do Guinness World Record como detentor
                da maior coleção de diferentes versões de uma mesma música</h3>

                <Retrato className="noBotMargin" src={Opovo} alt="Gerardo Barbosa Lima Filho" />
                <Legenda>
                    <figure>
                        <figcaption>
                            GERARDO BARBOSA cresceu cercado de música. A avó tocava piano, os tios integraram os
                            grupos 4 Ases e Um Coringa e Vocalistas Tropicais, e o pai foi discotecário da PRE-9 e
                            fundou a loja Vox. /FOTO FCO FONTELENE
                        </figcaption>
                    </figure>
                </Legenda>

                
                <h4>Natália Paiva - Especial para O POVO</h4>

                <div>
                    <p>"Olha, isso é Paul Desmond", interrompe momentaneamente a conversa; afinal, é preciso apreciar 
                    as versões que servem de trilha de fundo para a entrevista em curso. Mas, para chegar a essa conversa, 
                    foi preciso, em fevereiro de 1990, ele se dispor a remexer em LPs velhos, há muito tempo guardados e 
                    esquecidos nos fundos da casa do pai de um amigo. No remexido, eis que achou o disco de um então desconhecido 
                    ilustre, o trompetista estadunidense Chet Baker. "Aí começamos a ouvir o disco, um pouco chiado. Mas, na hora 
                    em que começou a música e ele começou a cantar - porque nessa gravação ele só canta, nem toca o trompete -, 
                    aquilo me marcou. Aí eu cheguei em casa e fui ver: realmente, eu tinha outras gravações daquela mesma música". 
                    A fim de facilitar a audição da paixão nova, o jeito foi juntar todas as versões que tinha em uma única fita 
                    cassete. Como a paixão não passava, mas aumentava com cada nota, com cada palavra, com a ausência ou presença 
                    de determinado instrumento, com a interpretação de cada artista, as fitas cassetes foram aumentando, foram 
                    surgindo os CDs, as MP3, os iTunes... Até que ele chegou ao espantoso número de 1.536 gravações - em 49 CDs e 
                    60 fitas - de My Funny Valentine, composição original de Richard Rodgers (música) e Lorenz Hart (letra) para o 
                    musical da Broadway Babes in Arms, de 1937. Em junho último, chegou pelo correio o certificado do Guinness 
                    World Records em seu nome: o cearense Gerardo Barbosa é o detentor da maior coleção de diferentes versões de 
                    uma mesma música. Basta dizer que o recorde anterior pertencia a um israelense que possuía 389 versões de 
                    Georgia on My Mind, de Hoagy Carmichael.</p>

                    <p>My Funny Valentine, música da dupla que chegou a emplacar outros grandes sucessos, como The Lady is a Tramp 
                    (1937) e Blue Moon (1934), só ganharia espaço midiático com os olhos azuis de Frank Sinatra, que escolheu-a 
                    para a primeira faixa de seu primeiro álbum, Songs for Young Lovers (1953). Com Chet Baker, a música tornou-se 
                    imortal, transcendeu espaço e tempo. Além de clássicos e contemporâneos do jazz - Miles Davis, Ella Fitzgerald, 
                    Paul Desmond, Diane Schuur, Sarah Vaughan, Diane Reeves -, e dos brasileiros Nara Leão (na versão intitulada 
                    Adeus no Cais), Dolores Duran e Baden Powell, Gerardo tem versões de artistas poucos conhecidos - a russa 
                    Victoria Tolstoi, a uzbequistanesa Aziza Mustafa Zadeh, o polonês Stanlislaw Soyka, a romena Teodora Enache - e 
                    releituras pouco usuais - o que esperar das versões da cantora Björk, do rapper Jason the Argonaut, do 
                    comediante Harpo Marx ou do ex-presidente dos EUA, Bill Clinton?</p>

                    <p>No começo da coleção, ele saía procurando a esmo em lojas e sebos de discos - aproveitava viagens e contava 
                    com a ajuda de amigos. Com a internet, vieram o acesso às lojas de discos de outros Países e a 
                    possibilidade de compra on-line. "Aí apareceram os sites de troca de música. Com o Napster, a coleção deu 
                    um avanço", conta. Triste constatar que, hoje, resta apenas o Soulseek, para o caçador de Valentinas. 
                    Quando baixou a última versão? "Semana passada", responde, em sorrisos. Foi a de um artista polonês, Iancsy 
                    Korossy. "Eu ainda continuo baixando essas músicas; tem o problema do direito autoral, mas eu baixo só para 
                    ouvir. De uns tempos para cá, eu venho também comprando faixas, tem sites que vendem músicas legalizadas". 
                    O bom é que, nessa cruzada, conheceu muitos artistas dos quais acabou se tornando fã - mais discos para a 
                    estante abarrotada.</p>

                    <p>A paixão pela música veio antes - muito antes - da paixão pelas Valentinas. "A gente morava no bairro de 
                    Fátima e, naquela época, nossa casa era vizinha à casa da minha avó; ela tocava muito piano". A gênese. "E 
                    depois, quanto eu era muito pequeno, meu pai foi trabalhar na PRE-9 (Ceará Rádio Club), foi discotecário 
                    durante 18 anos". As referências ainda se estendem: o pai também foi dono da então principal loja de discos 
                    da cidade - a loja Vox, fundada em 1955 -, o tio fez parte do grupo cearense Vocalistas Tropicais e o primo, 
                    do Quatro Azes e um Coringa. Quando menino, até tentou aprender instrumentos musicais, "mas não deu certo". 
                    "Fiquei mais ouvinte mesmo, dentro da loja, em casa, sempre ouvindo música". A última contagem de LPs, em 1983, 
                    chegou ao número aproximado de 1.300. "Os CDs, eu não sei...", responde. Mas, daí, começa a contar quantos 
                    CDs cabem em cada porta-CDs na estante e estima quantos porta-CDs cabem em cada prateleira reforçada com 
                    suportes de ferro. Nesse momento, perde a conta; sabe apenas que são bem mais de mil. "Já tá ficando um 
                    problema esse negócio de espaço. A idéia é digitalizar, passar tudo para o computador".</p>

                    <p>Mas, afinal, o que My Funny Valentine tem de tão especial? Vale dizer que o "Valentine" da música tanto 
                    é uma brincadeira com a palavra "namorado", em inglês, quanto com o nome próprio masculino Valentine, o 
                    protagonista do musical para o qual a música foi composta. Assim, explica-se de algum modo a estranheza 
                    que a letra causa, na qual quem canta acusa o amante de risível, pouco atraente e pouco esperto. De acordo 
                    com o pesquisador Will Friedwald, no livro Stardust Melodies - The Biography of Twelve of America's Most 
                    Popular Songs (Pantheon Books, New York), My Funny Valentine "é a idéia que um homem tem do que as mulheres 
                    pensam sobre os homens", e o fato de tantos homens a terem interpretado apenas atesta suas habilidades como 
                    intérpretes, já que a letra é genuinamente feminina. Em termos técnicos, Friedwald atesta que "a ambigüidade 
                    harmônica de Rodgers reflete diretamente na melancolia da letra de Hart". "O que torna toda a coisa memorável 
                    é a natureza triste-feliz da letra, brilhantemente espelhada pelos tons altos e baixos da natureza da música", 
                    completa. Para Gerardo, o ponto central da música é sua abertura, que permite várias leituras diferentes. 
                    "É uma música simples, mas que permite alguma complexidade. Na prática, se permitem muitas improvisações, 
                    você realmente descobre pedaços da música que você não tinha visto antes. Eu ainda sou surpreendido, de vez 
                    em quando", admite.</p>

                    <p>Gerardo intenta construir um sítio eletrônico sobre a música, com biografia e informações gerais sobre cada 
                    versão - e, no futuro, disponibilizar a audição de cada uma. Um outro projeto é Um Show Esquisito, onde 
                    pretende juntar artistas tão díspares quanto Karine Alexandrino e Fátima Santos para apresentarem, cada um, 
                    versões da música. "Às vezes, meus filhos e minha mulher reclamam um pouquinho", confessa, em sorrisos. 
                    Da poltrona da sala, ao ouvinte resta o esforço de não se deixar tragar pelas vagas jazzísticas que fogem 
                    das caixas de som, espalhadas pela casa. "Às vezes, dia de domingo, eu fico aqui e é dia de ouvir Valentine". 
                    Mas, na verdade, nem precisa ser domingo - como diz a canção, "each day is Valentine's...". Ele, porém, 
                    justifica a quase-obsessão: "as gravações são tão diferentes... Porque entra um instrumento, entra outro, e às 
                    vezes são bem diferentes mesmo. Então, eu gosto de ouvir". No fundo, Gerardo sabe que paixão não exige 
                    justificativa. Basta ela fazer seu coração sorrir.</p>

                </div>
            </Block>
        </Frame>
    );
}

export default Imprensa;

const Retrato = styled.img`
    max-width: 60%;
    max-height: 60%;
    margin-bottom: 0;
`;

const Legenda = styled.div`
    max-width: 60%;
    margin: auto;
    font-size: 1.2rem;

    @media (max-width: 425px) {
        max-width: 90%;
    }
`;
