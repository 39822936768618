import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
    height: -webkit-fill-available;
  }

  body {
    box-sizing: border-box;
    min-height: 100vh;
    min-height: -webkit-fill-available;

    font-family: 'Montserrat', sans-serif;
    background-color: #eeedc5;
  }  
`;

export default GlobalStyles;
