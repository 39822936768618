import React from 'react'
import styled from 'styled-components';

import song from '../../assets/audio-chet-baker-1953.mp3';

const Audio = () => {
  return (
    <Player>
    <figure>
    <figcaption>Chet Baker. Primeira versão adquirida.</figcaption>
    <audio
    controls
    //autoPlay
    controlsList="nodownload"
    src={song}>
    Your browser does not support the <code>audio</code> element.
    </audio>
    </figure>
    </Player>
    );
  }
  
  export default Audio;
  
  const Player = styled.div`
  display: block;
  margin: auto;
  
  & :focus {
    outline: 0;
  }
  
  & figcaption {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: #524c2a;
    
    @media (max-width: 750px) {
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
    }
  }
  
  & audio {
    margin-top: 2rem;
    
    @media (max-width: 750px) {
      width: 235px;
    }
  }
  `;
  