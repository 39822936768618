import React from 'react'
import styled from 'styled-components';

const Block = (props) => {
    return (
        <TextBlock>
            {props.children}
        </TextBlock>
    )
}

export default Block

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 70%;
    min-height: 60vh;
    margin: 0 auto 2rem;
    padding: 1rem 0;
    line-height: 2rem;
    text-align: justify;
    border-radius: 2rem;
    background-color: #fcfcf5;
    color: #524c2a;
    font-size: 1.4rem;
    overflow: hidden;

    & p, li{
        padding: 0.5rem 0;
    }

    & li {
        text-align: left;
    }

    & img {
        margin: 2rem auto;

        @media (max-width: 425px) {
        max-width: 95%;
        max-height: 95%;
        border: 3px solid #fff;
        }
    }

    & .noBotMargin {
        margin: 2rem auto 0;
    }

    & .flex {
        display: flex;
    }

    & .flexrow {
        flex-direction: row;
    }

    & .flexcolumn {
        flex-direction: column;
    }

    & .space-around {
        justify-content: space-around;
    }

    & .left {
        text-align: left;
    }

    & .right {
        text-align: right;
    }

    & .center {
        text-align: center;
    }

    & .small {
        font-size: 1.3rem;
    }

    & .brown {
        margin: 1.5rem 0;
        padding: 1.5rem;
        border-radius: 2rem;
        background-color: #ebe1c1;
    }

    & .border {
        margin: 2rem 0;
        padding: 0 1rem 1rem 1rem;
        border: 2px solid #524c2a;
        border-radius: 1rem;
    }

    & h1 {
        padding-top: 2rem;
        padding-bottom: 1rem;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
    }

    & h2 {
        padding: 1rem 0;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
    }

    & h3 {
        padding: 1rem 0;
        font-size: 1.5rem;
        font-weight: 500;
    }

    & h4 {
        padding-top: 1.5rem;
        font-size: 1.2rem;
        font-weight: 600;
    }

    @media (max-width: 425px) {
        max-width: 90%;
        padding: 0;
        background-color: #eeedc5;
        text-align: initial;

        & .brown {
            font-size: 1.2rem;
            font-weight: 500;
        }

        & a {
            overflow-wrap: break-word;
        }
    }
`;
