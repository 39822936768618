import React from 'react'
import styled from 'styled-components';

const Footer = () => {
    return (
        <StickyFooter>&copy; 2020</StickyFooter>
        )
    }
    
export default Footer
    
const StickyFooter = styled.div`
    height: 5rem;
    line-height: 5rem;
    padding-left: 1rem;
    background-color: #a3a380;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    color: #524c2a;
`;
    
    
    