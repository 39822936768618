import React from 'react'
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom';

import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import Brand from "./Brand";

import Home from '../home/Home';
import Musica from '../Musica/Musica';
import Compositores from '../compositores/Compositores';
import Interpretes from '../interpretes/Interpretes';
import Guinness from '../guinness/Guinness';
import Colecao from '../Colecao/Colecao';
import Imprensa from '../imprensa/Imprensa';
import Contato from '../contato/Contato';
import Agradecimentos from '../agradecimentos/Agradecimentos';

const Navbar = (props) => {
    const barAnimation = useSpring({
        from: { transform: 'translate3d(0, -10rem, 0)' },
        transform: 'translate3d(0, 0, 0)',
    });

    return (
        <>
          <Router>
            <NavBar style={barAnimation}>
                <FlexContainer>
                    <NavLinks>
                        <NavLink to="/" exact activeClassName="active"><i className='fa fa-home'></i></NavLink>
                        <NavLink to="/musica" exact activeClassName="active">A MÚSICA</NavLink>
                        <NavLink to="/compositores" exact activeClassName="active">COMPOSITORES</NavLink>
                        <NavLink to="/colecao" exact activeClassName="active">A COLEÇÃO</NavLink>
                        <NavLink to="/interpretes" exact activeClassName="active">INTERPRETES</NavLink>
                        <NavLink to="/guinness" exact activeClassName="active">GUINNESS</NavLink>
                        <NavLink to="/imprensa" exact activeClassName="active">IMPRENSA</NavLink>
                        <NavLink to="/contato" exact activeClassName="active">CONTATO</NavLink>
                        <NavLink to="/agradecimentos" exact activeClassName="active">AGRADECIMENTOS</NavLink>
                    </NavLinks>
                </FlexContainer>
                <BurgerWrapper>
                    <BurgerMenu
                        navbarState={props.navbarState}
                        handleNavbar={props.handleNavbar}
                    />
                    <Brand />
                </BurgerWrapper>                
            </NavBar>
            <CollapseMenu
                navbarState={props.navbarState}
                handleNavbar={props.handleNavbar}
            />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/musica">
                <Musica />
              </Route>
              <Route path="/compositores">
                <Compositores />
              </Route>
              <Route path="/colecao">
                <Colecao />
              </Route>
              <Route path="/interpretes">
                <Interpretes />
              </Route>
              <Route path="/guinness">
                <Guinness />
              </Route>
              <Route path="/imprensa">
                <Imprensa />
              </Route>
              <Route path="/contato">
                <Contato />
              </Route>
              <Route path="/agradecimentos">
                <Agradecimentos />
              </Route>
            </Switch>
          </Router>
        </>
    )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #524c2a;
  z-index: 1;
  font-size: 1.3rem;
`;

const FlexContainer = styled.div`
  justify-content: center;
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  height: 5rem;

  @media (max-width: 960px) {
      display: none;
    }
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  line-height: 5rem;

  & a {
    transition: all 300ms linear 0s;
    color: #eeedc5;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 500;

    & i {
      font-size: 2rem;  
      vertical-align: middle;
    }

    &:hover {
      color: #eeedc5;
      text-shadow: 0 0 1px rgba(244, 249, 246, 0.92);
    }
    
  }

  & a.active {
    text-decoration: underline #eeedc5;
    font-weight: 600;
  }
`;

const BurgerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  height: 5rem;

  @media (min-width: 961px) {
    display: none;
  }
`;
