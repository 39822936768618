import React from 'react';
import styled from 'styled-components';

import Frame from '../Frame';
import Block from '../Block';

import certificate from '../../assets/Guinness_crop.jpg';
import claimid from '../../assets/claim_status.JPG';

const Guinness = () => {
    return (
        <Frame>
            <Block>
                <div className="border center">
                    <h2>Recorde Anterior</h2>

                    <p>Paltiel Nir (Israel)</p>
                    <p>389 versions de Georgia on my mind</p>
                    <p>Música de Hoagy Carmichael</p>
                </div>

                <Certificate src={certificate} alt="Guinness world record certificate"/>

                <div className="brown center">
                    <h3>O Guinness World of Records reconheceu as 1384 gravações como recorde mundial em novembro de 2005.</h3>

                    <h3>Hoje a coleção tem um total de 4.020 gravações em arquivos digitalizados para audição.</h3>
                </div>

                <ClaimId src={claimid} alt="Claim Status" />

                <div className="border center">
                    <h2>Um hino venceu uma canção</h2>

                    <p>Noticia do Guinness</p>
                    <p>The largest collection of versions of the same song is 3.049 different recorded interpretations of the hymn  'Amazing Grace' by John Newton, collected by Allan Chasanoff and Raymon Elozua.</p>
                </div>
            </Block>
        </Frame>
    );
}

export default Guinness;

const Certificate = styled.img`
    max-width: 60%;
    max-height: 60%;
`;

const ClaimId = styled.img`
    max-width: 70%;
    max-height: 70%;
`;
