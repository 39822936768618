import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { useSpring, animated } from 'react-spring';

const CollapseMenu = (props) => {
  if (!props.navbarState) return null;
  return <SmallMenu {...props} />
}

const SmallMenu = ({ navbarState, handleNavbar }) => {
  const { open } = useSpring({ open: navbarState ? 0 : 1 });
  
  return (
    <>
    <CollapseWrapper style={{
      transform: open.interpolate({
        range: [0, 0, 0, 1],
        output: [0, -20, 0, -200],
      }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
    }}
    >
    <NavLinks>
    <Link onClick={handleNavbar} to="/">HOME</Link>
    <Link onClick={handleNavbar} to="/musica">A MÚSICA</Link>
    <Link onClick={handleNavbar} to="/compositores">COMPOSITORES</Link>
    <Link onClick={handleNavbar} to="/colecao">A COLEÇÃO</Link>
    <Link onClick={handleNavbar} to="/interpretes">INTERPRETES</Link>
    <Link onClick={handleNavbar} to="/guinness">GUINNESS</Link>
    <Link onClick={handleNavbar} to="/imprensa">IMPRENSA</Link>
    <Link onClick={handleNavbar} to="/contato">CONTATO</Link>
    <Link onClick={handleNavbar} to="/agradecimentos">AGRADECIMENTOS</Link>
    </NavLinks>
    </CollapseWrapper>
  </>
  );
  
};

export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
z-index: 1;
background: #8c8047;
position: fixed;
top: 5rem;
left: 0;
right: 0;
`;

const NavLinks = styled.div`
display: flex;
flex-direction: column;
list-style-type: none;
padding: 2rem 1rem 2rem 2rem;
transition: all 300ms linear 0s;

& a {
  font-size: 1.5rem;
  line-height: 2.5;
  color: #eeedc5;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
  
  &:hover {
    color: #eeedc5;
    text-shadow: 0 0 1px rgba(244, 249, 246, 0.92);
  }
}
`;
