import React, { createContext, useState, useEffect, useContext } from "react";

const urlJson = "tabela.json";

const TracksContext = createContext({
  tracks: [],
  loading: false,
});

export const TracksProvider = ({ children }) => {
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        setLoading(true);
        const result = await fetch(urlJson);
        const tracksData = await result.json();
        setTracks(tracksData);
      } catch (error) {
        console.log("Fetch Tracks Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTracks();
  }, []);
  return (
    <TracksContext.Provider value={{ tracks, loading }}>
      {children}
    </TracksContext.Provider>
  );
};

export const useTracks = () => useContext(TracksContext);
export const TracksConsumer = () => TracksContext.Consumer;
